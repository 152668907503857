.container {
    position: relative;

    .popularLinks {
        --grid-spacing: 10px;
    }

    .searchPanelContainer {
        margin: var(--menu-spacing) 0;
    }

    .searchPanelSlider {
        overflow: hidden;
    }

    .searchPanel {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        min-height: 100%;
        background: var(--color-white);
    }

    .brands {
        padding: 0 var(--side-spacing);
    }

    .section {
        --grid-spacing: var(--spacing);
        --row-spacing: var(--spacing);

        padding: var(--menu-section-spacing);
        /* border-top: var(--menu-section-border); */

        /* &:first-of-type {
            border-top: 0;
        } */

        &.brandsSection {
            padding-top: 0;
            border-top: 0;
        }
    }

    .items {
        padding: 0 var(--side-spacing);
        scroll-padding: 0 var(--side-spacing);
    }

    .linksMenu {
        padding: var(--spacing) 0;
        border-top: var(--menu-section-border);
        color: rgba(39, 39, 39, 0.5);
    }

    .linkItem {
        &::after {
            margin: 0 var(--menu-spacing);
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &.searchOpened {
        overflow: hidden;

        .searchPanel {
            position: fixed;
            overflow: auto;
            height: 100%;
            -webkit-overflow-scrolling: touch;
            overscroll-behavior: contain;
        }
    }
}
